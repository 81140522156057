<template>
  <div class="formContainer">
    <div class="main">
      <el-tree :data="regionList" :props="treeSelectProps" show-checkbox node-key="code" accordion ref="tree" default-expand-all @check-change="checkChange" check-on-click-node />
    </div>

    <div class="formBox">
      <el-form ref="dform" label-position="right" label-width="140px" :model="form">
        <el-form-item label="频点列表">
          <div class="itemList" v-for="(item,index) in itemList" :key="index" style="margin-bottom: 10px">
            <div>
              <span>频点序号</span>
              <span>{{index + 1}}</span>
            </div>
            <div>
              <span>频点优先级</span>
              <el-input-number v-model="itemList[index].priority" size="small" :min="1" :max="5" />
            </div>
            <div>
              <span>频率</span>
              <el-input-number v-model="itemList[index].frequency" size="small" :precision="2" :min="0.00" :max="9999.99" />
            </div>

            <el-button class="delbtn" v-if="itemList.length > 1" type="danger" round @click="del(index)">删除</el-button>
          </div>

          <el-button v-if="itemList.length < 5" style="display: block;margin-top: 10px" round type="primary" @click="addList">增加列表</el-button>

        </el-form-item>

        <el-form-item label="维持时间(秒)" prop="sendTimes" :rules="[{ required: true, message: '维持时间(秒) 不能为空' }]">
          <el-input-number :min="1" :max="255" clearable autocomplete="off" v-model="form.sendTimes" placeholder="请输入 维持时间(秒)"></el-input-number>
        </el-form-item>

        <el-form-item label="发送地址信息" prop="regionCodes" :rules="[{ required: true, message: '发送地址信息 不能为空' }]">
          <el-input readonly clearable type="textarea" autocomplete="off" v-model="form.regionCodes" placeholder="请选择 发送地址信息"></el-input>
        </el-form-item>

        <el-button  plain @click="addForm('dform')" class="searchBtn">提交</el-button>
      </el-form>
    </div>
  </div>
</template>
<script>
import { setRdsFreqPoint } from "@/api/terminalRegulate/generalVolume";
import { getRegionEquip } from "@/api/system/region";
import {nextTick, onMounted, reactive, ref, toRefs} from "vue";
import {ElMessage} from "element-plus";

  export default {
    setup(){
      let dform = ref(null);
      let tree = ref(null);
      let state = reactive({
        codes:[],
        itemList:[
          {
            serialNo:"",
            priority:"",
            frequency:""
          }
        ],
        form:{
          sendTimes:1,
        },
        regionList: [],
        codeList: [],
        treeSelectProps: {
          label: "name",
          value: "id",
          key: "code",
          children: "children"
        }
      })

      onMounted(()=>{
        getRegionEquip().then(res => {
          if (res.data.success) state.regionList = res.data.data.list;
        });
      })

      function getSimpleCheckedNodes(store) {
        const checkedNodes = [];
        const traverse = function(node) {
          const childNodes = node.root ? node.root.childNodes : node.childNodes;
          childNodes.forEach(child => {
            if (child.checked) {
              checkedNodes.push(child.data);
            }
            if (child.indeterminate) {
              traverse(child);
            }
          });
        };
        traverse(store);
        return checkedNodes;
      }

      function checkChange(node, status) {
        state.codeList = getSimpleCheckedNodes(tree.value.store);
        state.form.regionCodes = state.codeList.map(item=>item.name).join(",");
        state.codes = state.codeList.map(item=>item.code).join(",");
      }

      function del(_index){
        state.itemList.splice(_index,1);
      }

      function addList(){
        let len = state.itemList.length
        state.itemList.push({
          serialNo:len + 1,
          priority:"",
          frequency:""
        })
      }

      function addForm(){
        let arrayJsonData =  state.itemList.map((item,index)=>{
          return{
            serialNo:index + 1,
            priority: item.priority,
            frequency: item.frequency.toFixed(2)
          }
        })

        dform.value.validate(async (valid) => {
          if (valid) {
            let { sendTimes } = state.form;
            let obj = new FormData();
            obj.append("sendTimes", sendTimes);
            obj.append("regionCodes", state.codes);
            obj.append("arrayJsonData", JSON.stringify(arrayJsonData));
            let res = await setRdsFreqPoint(obj)
            if (res.data.success) {
              ElMessage({type: "success", message: "操作成功！"});
              nextTick(()=>{
                dform.value.resetFields();
              })
            }
          }else {
            return false
          }
        })
      }


      return{
        addForm,
        dform,
        del,
        addList,
        tree,
        checkChange,
        ...toRefs(state)
      }
    }
  }
</script>
<style lang="scss" scoped>
.itemList{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .delbtn{
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    right: -100px;
    z-index: 9;
  }
  >div{
    width: 33.333%;
    display: flex;
    align-items: center;
    justify-content: center;
    >span{
      width: 200px;
    }

  }
}
.formContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.main {
  width: 300px;
  ::-webkit-scrollbar {
    width: 0;
  }
}
.el-tree {
  height: 700px !important;
  overflow-y: scroll;
}

.formBox {
  width: 1000px;
}

.el-tree-node__content {
  margin-top: 20px !important;
}
</style>